import React, { useState, useRef } from "react";

import { Modal, Radio, Form, Button } from "antd";
import axios from "axios";
import "./App.css";
import ModalE from "./components/Modal";
import DetailsModal from "./components/DetailsModal";
import { IoIosInformationCircleOutline } from "react-icons/io";
import "antd/dist/antd.css";
import LoadingOverlay from "react-loading-overlay";
import EmptyScreen from "./components/EmptyScreen";

const { confirm } = Modal;

// const url = "http://localhost:3085";
const url = "https://api.selectiveyazilim.com/gkmobilya";

const Component = (props) => {
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [barcode, setBarcode] = useState(null);
  const [images, setImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [detailsModal, setIsDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quests, setQuests] = useState([]);
  const [text, setText] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [param, setParams] = useState({
    sipno: "",
    sipsr: "",
    musadi: "",
    mail: "",
    tarih: "",
    firma: "",
  });

  const [answers, setAnswers] = useState({
    q1: true,
    q2: true,
    q3: true,
    q4: true,
    q5: true,
    q6: true,
    q7: true,
    q8: true,
    q9: true,
    q10: true,
    q11: true,
    q12: true,
    q13: true,
    q14: true,
    q15: true,
  });

  const gonder = (img) => {
    /*  axios.post("https://www.cempolat.com/test", {
      image: img,
      barcode: barcode,
    });*/
  };

  React.useEffect(() => {
    const params = window.location.search;
    const para = new URLSearchParams(params);
    const sipno = para.get("sipno");
    const sipsr = para.get("sipsr");
    const musadi = para.get("musadi");
    const firma = para.get("firma");
    const mail = para.get("mail");
    const tarih = para.get("tarih");
    const urunadi = para.get("urunadi");
    const out = para.get("out");
    if (parseInt(out) === 0) {
      setQuests(questions);
      setText(`${tarih} tarihli ${musadi} müşterinizin ${urunadi} adlı ürünü üretilmiştir. \n Kalite
      kontrol testlerinden geçmiştir. \n Ürün fotoğrafları Ektedir.`);
      setHeaderText("GK Mobilya Ürün Fotoğraflarınız.");
    } else {
      setText(`The product named ${urunadi} of your customer ${musadi} with the date ${tarih} has been produced. \nQuality
      passed the control tests. \n Product photos are attached.`);
      setQuests(enQuestions);
      setHeaderText("GK Mobilya Koltuk Product Images.");
    }
    setParams({
      mail: mail,
      musadi: musadi,
      sipno: sipno,
      sipsr: sipsr,
      firma: firma,
      tarih: tarih,
      urunadi: urunadi,
      out: parseInt(out),
    });
  }, []);

  const onFinish = (params) => {
    axios
      .post(url + "/test/deneme", {
        images: images,
        barcode: barcode,
        answers: answers,
        params: param,
        questions: quests,
        out: params.out,
        text: text,
        headerText: headerText,
      })
      .then((res) => {
        setIsLoading(false);
        window.open("about:blank", "_self");
        window.close();
      });
  };

  function showConfirm(params) {
    confirm({
      title: "Mail Gönderilecek Onaylıyormusunuz?",
      icon: <IoIosInformationCircleOutline style={{ fontSize: 30 }} />,
      content: "",
      okText: "Onayla",
      cancelText: "İptal Et",
      onOk() {
        setIsLoading(true);
        onFinish(params);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const enQuestions = [
    {
      question: "Is the fabric correct?",
      key: "q1",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the seam straight?",
      key: "q2",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the sewing thread correct?",
      key: "q3",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Are the printing seams straight?",
      key: "q4",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the font inside the case properly placed?",
      key: "q5",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Are the arms and font aligned?",
      key: "q6",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the lower backing correct?",
      key: "q7",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the case and font fabric correct?",
      key: "q8",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Are the fabrics on the inner back and font stretched?",
      key: "q9",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Has the seat cushion hardness been checked?",
      key: "q10",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Have the mechanisms been checked by operating?",
      key: "q11",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the fabric defect free or spotless?",
      key: "q12",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Is the foot paint correct?",
      key: "q13",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Have the sound systems been checked?",
      key: "q14",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question:
        "Have the corner sofa set been installed and its dimensions checked?",
      key: "q15",
      fakeRes: "Yes",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
  ];

  const questions = [
    {
      question: "Kumaş doğru mu?",
      key: "q1",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Dikiş düzgün mü?",
      key: "q2",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Dikiş ipi doğru mu?",
      key: "q3",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Baskı dikişleri düzgün mü?",
      key: "q4",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Kasa içindeki font düzgün yerleştirilmiş mi?",
      key: "q5",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Kollarla font aynı hizzadamı?",
      key: "q6",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Alt telası düzgün mü?",
      key: "q7",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Kasa ve font kumaşı düzgün mü?",
      key: "q8",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "İç arka ve fonttaki kumaşlar gergin mi?",
      key: "q9",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Oturum minderi sertlik kontrolü yapıldı mı?",
      key: "q10",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Mekanizmalar çalıştırılarak kontrol edildi mi?",
      key: "q11",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Kumaş defosuz veya lekesiz mi?",
      key: "q12",
      fakeRes: "Hayır",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Ayak boyası düzgün mü?",
      key: "q13",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Ses sistemleri kontrol edildi mi?",
      key: "q14",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    {
      question: "Köşe koltuk kurulup ölçüleri kontrol edildi mi?",
      key: "q15",
      fakeRes: "Evet",
      answers: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
  ];

  return (
    <LoadingOverlay active={isLoading} spinner text="Yükleniyor...">
      {!param.sipno ? (
        <EmptyScreen url={url} loading={isLoading} setLoading={setIsLoading} />
      ) : (
        <div className="App" style={{ minHeight: "100vh", maxHeight: "100vh" }}>
          <div
            style={{
              width: "66%",
              minWidth: 360,
              maxHeight: "90vh",

              minHeight: "100%",
              backgroundColor: "rgba(255,255,255,0.6)",
              borderRadius: 15,
              padding: 15,
            }}
          >
            <input
              style={{
                padding: 10,
                width: "95%",
                marginTop: 15,
                marginBottom: 15,
                color: "white",
                fontWeight: 500,
                fontSize: 20,
                backgroundColor: "rgba(0,0,0,0.6)",
              }}
              type="text"
              value={param.firma + " / " + param.musadi + " / " + param.urunadi}
              disabled={true}
            />
            <div style={{ height: "100%" }}>
              <div
                style={{
                  height: "63vh",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {images.map((image) => {
                  return (
                    <div style={{ height: 130, width: 130, margin: 10 }}>
                      <button
                        style={{
                          position: "relative",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: 99,
                          border: 0,
                          width: 40,
                          height: 40,
                          top: 20,
                          right: 50,
                        }}
                        onClick={() => {
                          var a = [];
                          for (var i = 0; i < images.length; i++) {
                            if (images[i] !== image) {
                              a.push(image);
                            }
                          }
                          setImages(a);
                        }}
                      >
                        X
                      </button>
                      <button
                        style={{
                          width: "90%",
                          height: "90%",
                          border: 0,
                          backgroundColor: "transparent",
                        }}
                        onClick={() => {
                          setIsDetailsModal(true);
                          setSelectedImage(image);
                        }}
                      >
                        <img
                          src={image}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                paddingTop: 15,
                maxHeight: "100vh",
                paddingBottom: 15,
              }}
            >
              <button
                style={{
                  width: "34%",
                  padding: 15,
                  borderRadius: 15,
                  background: "green",
                  color: "white",
                  border: 0,
                }}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Fotoğraf Çek
              </button>
              <button
                style={{
                  width: "31%",
                  padding: 15,
                  borderRadius: 15,
                  background: "green",
                  color: "white",
                  border: 0,
                }}
                onClick={() => setImages([])}
              >
                Sıfırla
              </button>
            </div>
          </div>

          <div
            style={{
              width: "27%",
              minWidth: 360,
              backgroundColor: "rgba(255,255,255,0.6)",
              padding: 15,
              maxHeight: "90vh",
              borderRadius: 15,
              overflowY: "auto",
            }}
          >
            <Form name="dsfs" onFinish={showConfirm}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {questions.map((question, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        margin: 20,
                        display: "block",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    >
                      <h3
                        key={index}
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        {question.question}
                      </h3>
                      <Form.Item name={question.key}>
                        <Radio.Group
                          defaultValue={true}
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {question.answers.map((answer, idx) => {
                            return (
                              <Radio.Button
                                key={idx}
                                style={{ margin: 5 }}
                                value={answer.value}
                              >
                                {answer.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      width: 230,
                      borderRadius: 15,
                      height: 40,
                      background: "green",
                      color: "white",
                      border: 0,
                      marginBottom: 20,
                    }}
                    htmlType="submit"
                  >
                    Onayla
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <ModalE
            isOpen={modalOpen}
            setIsOpen={setModalOpen}
            setImages={setImages}
            images={images}
          />
          <DetailsModal
            isOpen={detailsModal}
            setIsOpen={setIsDetailsModal}
            image={selectedImage}
          />
        </div>
      )}
    </LoadingOverlay>
  );
};

export default Component;
